import _get from 'lodash/get';
import appConfig from '../config.json';
import _isEmpty from 'lodash/isEmpty';

/**
 * transform the data to render as react components
 * * @param {*} transformRenderComponents
 */
export const transformRenderComponents = rawJson => {
  const pageHeaderComponents = _get(
    rawJson,
    ['sitecore', 'route', 'placeholders', 'header-page'],
    [],
  );
  const pageBodyComponents = _get(
    rawJson,
    ['sitecore', 'route', 'placeholders', 'page-body'],
    [],
  );
  const pageFooterComponents = _get(
    rawJson,
    ['sitecore', 'route', 'placeholders', 'page-footer'],
    [],
  );
  const isNonTransactional = _get(
    rawJson,
    ['sitecore', 'route', 'fields', 'isNonTransactional'],
    false,
  );

  const isLoggedIn = _get(rawJson, ['sitecore', 'context', 'user'], null);
  const getLanguageCode = _get(rawJson, ['sitecore', 'context', 'language'], 'en-US');
  const componentsData = [...pageHeaderComponents, ...pageBodyComponents, ...pageFooterComponents];

  const components = componentsData.reduce((data, item) => {
    if (item.componentName && item.dataSource) {
      const componentName = item.componentName.replace(/ +/g, '');
      return {
        ...data,
        [componentName + '__' + item.uid]: {
          'cms:resourceType': componentName,
          id: item.uid,
          isNonTransactional : isNonTransactional,
          isLoggedIn: isLoggedIn ? true : false,
          language: getLanguageCode,
          dataSource: item.dataSource.replace(/[{}]/g, ''),
          params: item.params,
          ...item.fields
        },
      };
    }
    return { ...data };
  }, {});
  console.log('components : > ', components);
  return components;
};

/**
 * get the configuration data from JSON file.
 * @param {Object} rawJson icon
 * @returns application configuration data
 */
export const getAppConfig = rawJson => {
  const getLanguageCode = _get(rawJson, ['sitecore', 'context', 'language'], 'en-US');
  const code = getLanguageCode.split('-')[1];
  if (Object.keys(appConfig).includes(code)) {
    return _get(appConfig, [code], null);
  }
  return _get(appConfig, ['US'], null);
};

export const pageName = rawJson => {
  const name = _get(rawJson, ['sitecore', 'route', 'fields','pageDisplayName','value'], 'n/a');
  const loggedIn = _get(rawJson, ['sitecore', 'context', 'user'], '');
  const languageCode = _get(rawJson, ['sitecore', 'context', 'language'], '');
  return { pageName: name, loggedIn: loggedIn, languageCode: languageCode };
};

export const commaSeparated = arr => {
  return !_isEmpty(arr) && arr.join(', ');
};

export const currencyPlacement = (currency, amount, isShowMinus = false) => {
  const modifiedAmount = Number.parseFloat(amount).toFixed(2);
  if (orderViewModel.headerFooterLanguageCode == 'fr-CA') {
    if(isShowMinus) {
      return `-${modifiedAmount.replace('.', ',')} ${currency}`;
    }
    return `${modifiedAmount.replace('.', ',')} ${currency}`;
  }
  if(isShowMinus) {
    return `-${currency}${modifiedAmount}`;
  }
  return currency + modifiedAmount;
};

export const getComponentList = pageLayout => {
  let list = [];
  const name = _get(pageLayout, ['sitecore', 'route', 'name'], '');
  if (name === 'menu') {
    const pageBodyComponents = _get(
      pageLayout,
      ['sitecore', 'route', 'placeholders', 'page-body'],
      null,
    );
    const menuList = ['Categories', 'Favorites', 'Bestseller'];
    menuList.forEach(menuItem => {
      const component = pageBodyComponents.find(comp => comp.componentName === menuItem);
      if (component) {
        list.push(component.componentName);
      }
    });
  }
  return list;
};

export const getUtcTime = () => {
  const d = new Date(),
    dateFormat =
      [(d.getUTCMonth() + 1).toString().padStart(2, '0'), d.getUTCDate().toString().padStart(2, '0'), d.getUTCFullYear()].join('-')
      + '|' + [d.getUTCHours().toString().padStart(2, '0'), d.getUTCMinutes().toString().padStart(2, '0')].join(':');
  return dateFormat;
};
export const reactEngageApptentiveEvent = (clickActionName,isExistInEventNameList,url,token)=>{
  return  engageApptentiveEvent(clickActionName,isExistInEventNameList,url,token);
};
export const containsHTMLTags = (inputString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(inputString, 'text/html');
  return doc.body.childElementCount > 0;
};
export const getPageName = ()=>{
  const path = window.location.pathname
  const arr = path.split('/');
  return arr[arr.length-1];
}
export const getLocalOffsetTime = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const rawUtcOffsetMinutes = new Date().getTimezoneOffset();
  const sign = rawUtcOffsetMinutes < 0 ? '+' : '-';
  const absoluteOffsetMinutes = Math.abs(rawUtcOffsetMinutes);
  const hours = String(Math.floor(absoluteOffsetMinutes / 60)).padStart(2, '0');
  const minutes = String(absoluteOffsetMinutes % 60).padStart(2, '0');
  const utcOffsetString = `${sign}${hours}:${minutes}`;
 
  return { timeZone, utcOffsetString };
};
export function checkRewardsPageCondition(pagename) {
  if (pagename !== 'rewards-activity') {
    return true;
  }
  return _isEmpty(orderViewModel.currentOrder.cartId) && (!_isEmpty(orderViewModel.store.id));
}