import DancingDotsGreen from '../../src/assets/RemoteOrder/confettie/DancingDotsGreen.json';
import DancingDotsGrey from '../../src/assets/RemoteOrder/confettie/DancingDotsGrey.json';
import DancingDotsWhite from '../../src/assets/RemoteOrder/confettie/DancingDotsWhite.json';
import { GREEN, GREY } from '../constants/App';
export const getAnimationData = (animationColor: 'white' | 'grey' | 'green') => {
    switch (animationColor) {
      case GREEN:
        return DancingDotsGreen;
      case GREY:
        return DancingDotsGrey;
      default:
        return DancingDotsWhite;
    }
  };