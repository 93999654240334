/**
 * Translations value
 * @memberof constants/Translations
 */
export const SEE_ALL = 'See All';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const SEE_LESS = 'See Less';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const QUANTITY = 'Quantity';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const CLICK_TO_REMOVE = 'remove one item';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const CLICK_TO_ADD = 'add one item';

 /**
 * Translations value
 * @memberof constants/Translations
 */
export const ONE_ITEM = 'one item';


/**
 * Translations value
 * @memberof constants/Translations
 */
export const NO_THANKS = 'No, thanks';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const MAKE_IT_A_COMBOQ = 'Make it a combo ?';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const MAKE_IT_A_COMBO = 'Make it a combo';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const SELECT_ONE_FROM_BELOW = 'Select 1 from below. Sides/drinks selected in next steps';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const FOOT_NOTE =
  '2,000 calories a day is used for general nutrition advice, but calorie needs vary. Additional nutrition information available upon request.';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const ITEM_NAME = 'ITEM NAME';

/**
 * Translations value
 * @memberof constants/Translations
 */
export const YOUR_COMBO = 'Your Combo';

export const SOUP_ID = 886;

export const COOKIE_PACK = 847;

export const FOUNTAIN_DRINK_ID = 786;
