import React from 'react';
import _get from 'lodash/get';
import getComponentFromRegistry from './Registry';
const CMS_RESOURCE_TYPE = 'cms:resourceType';

/**
 * Find and return a component from the registry
 * @param cmsResourceType Component type
 * @param additionalProps additional component props
 * @returns {Array}
 */
function getComponent(cmsResourceType, additionalProps = {}) {
  const registryEntry = getComponentFromRegistry(cmsResourceType);
  return Array.isArray(registryEntry) && registryEntry.length > 1
    ? registryEntry
    : [registryEntry, additionalProps];
}

/**
 * Find Each level component from JSON structure provided by CMS
 * @param node Component type eg. { "Title": "foo", "cms:resourceType": "componentName", }
 * @param propsTransformer additional component props { ...props, className: "foo" , apiDriven: true }
 * @returns {Array} react components
 */
export const renderNestedComponents = (node, propsTransformer) => {
  const componentIndexMap = {};

  return Object.keys(node).reduce((acc, item) => {
    if (item === CMS_RESOURCE_TYPE || !node[item] || typeof node[item] !== 'object') return acc;
    const component_type = _get(node, `[${item}][${CMS_RESOURCE_TYPE}]`, 'no-type');
    if (!componentIndexMap[component_type]) {
      componentIndexMap[component_type] = 1;
    } else {
      componentIndexMap[component_type] += 1;
    }

    const index = componentIndexMap[component_type];
    const component = renderComponentNode(node[item], index, propsTransformer);
    return component ? [...acc, component] : acc;
  }, []);
};

export const renderComponentNode = (node, index, propsTransformer) => {
  const cmsResourceType = node[CMS_RESOURCE_TYPE];
  if (!cmsResourceType) return null;
  let [Component, additionalProps] = getComponent(cmsResourceType);

  if (!Component) {
    return null;
  }

  const uniqKey = `${cmsResourceType}-${index}`;
  const childProps = { ...node, uniqKey, ...additionalProps };
  const transformProps = propsTransformer ? propsTransformer(childProps) : childProps;
  delete transformProps[CMS_RESOURCE_TYPE];
  return <Component key={uniqKey} {...transformProps} />;
};
