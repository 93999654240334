import {
  CUSTOMIZER,
  DELIVERY_LOCATOR,
  FALLBACK_NA,
  MENU,
  ORDER_CONFIRMATION,
  ORDER_MANAGEMENT,
  PROFILE,
  RESTAURANT,
} from '../constants/AnalyticsConstants';
import { useEffect } from 'react';
import { getScreenBreakpoint, getLocalTime } from '../Utils/analyticsUtils';
import useStore from '../store/useStore';
import { pageName, pageSection, reactAttemptTrack } from '../components/Analytics/AnalyticsNew';
import { matchUrl } from '../Utils/componentUtils';

const useAnalytics = ({ ...props }) => {
  const {
    analyticsProducts,
    pageDetails,
    attemptTracter,
    isSetFavourite,
    isSetBestSeller,
    totalCards,
    selectedCard,
    cartID,
  } = useStore();
  const getBestSeller = isSetBestSeller ? 'best sellers' : '';
  const favOrBestSeller = isSetFavourite ? 'favorites' : getBestSeller;
  useEffect(() => {
    const pagesArray = [
      RESTAURANT,
      MENU,
      ORDER_MANAGEMENT,
      CUSTOMIZER,
      ORDER_CONFIRMATION,
      PROFILE,
      DELIVERY_LOCATOR,
    ];
    if (matchUrl(pagesArray)) {
      window.fwhtrk_new = {
        page: {
          hostName:
            window.location.host.match(/(\w|-)+(?=(\.(com)(\.|\/|$)))/g) &&
            window.location.host.match(/(\w|-)+(?=(\.(com)(\.|\/|$)))/g)[0],
          section: analyticsProducts?.sectionName ?? pageDetails ?? pageSection(props.pageName),
          name: analyticsProducts?.pageName ?? pageDetails ?? pageName(props.pageName),
          locale: props.languageCode,
          componentTitle: analyticsProducts.componentTitle || '',
          clickActionName: analyticsProducts.clickActionName || '',
          virtualName: analyticsProducts.virtualName || '',
          type: analyticsProducts.type || '',
          promoCode: analyticsProducts.promoCode || '',
          orderStatus: analyticsProducts.orderStatus || '',
        },
        event: {
          errorMessage: analyticsProducts.errorMessageEvent || 0,
          warningMessage: analyticsProducts.warningMessageUI || 0,
          errorMessageMW: analyticsProducts.errorMessageMW || 0,
          errorMessageUI: analyticsProducts.errorMessageUI || 0,
          bannerImpression: analyticsProducts.bannerMessageEvent || 0,
          bannerDismiss: analyticsProducts.bannerDismiss || 0,
          cartOpen: analyticsProducts.cartOpen || 0,
          toasted: analyticsProducts.toasted || 0,
          upsellProductAdded: analyticsProducts.upsellProductAddedEvent || 0,
          startonlineorder: analyticsProducts.startonlineorder || 0,
          itemaddedtobag: analyticsProducts.itemaddedtobag || 0,
          productPriceAdded: analyticsProducts.productPriceAdded || 0,
          productQuantityAdded: analyticsProducts.productQuantityAdded || 0,
          ComboPriceAdded: analyticsProducts.ComboPriceAdded || 0,
          MIAMPriceAdded: analyticsProducts.MIAMPriceAdded || 0,
          ExtrasPriceAdded: analyticsProducts.ExtrasPriceAdded || 0,
          sandwichCustomized: analyticsProducts.sandwichCustomized || 0,
          CYO: analyticsProducts.CYO || 0,
          productPriceCheckout: analyticsProducts.productPriceCheckout || 0,
          productQuantityCheckout: analyticsProducts.productQuantityCheckout || 0,
          MIAMPriceCheckout: analyticsProducts.MIAMPriceCheckout || 0,
          ExtrasPriceCheckout: analyticsProducts.ExtrasPriceCheckout || 0,
          ComboPriceCheckout: analyticsProducts.ComboPriceCheckout || 0,
          vechicleSelected: analyticsProducts.vechicleSelected || 0,
          productPriceUpdate: analyticsProducts.productPriceUpdate || 0,
          productQuantityUpdate: analyticsProducts.productQuantityUpdate || 0,
          MIAMPriceUpdate: analyticsProducts.MIAMPriceUpdate || 0,
          kidsMealContinue: analyticsProducts.kidsMealContinue || 0,
          kidsMealSelected: analyticsProducts.kidsMealSelected || 0,
          ExtrasPriceUpdate: analyticsProducts.ExtrasPriceUpdate || 0,
          updateCart: analyticsProducts.updateCart || 0,
          MIAMSelected: analyticsProducts.MIAMSelected || 0,
          ComboPriceUpdate: analyticsProducts.ComboPriceUpdate || 0,
          drinkSelected: analyticsProducts.drinkSelected || 0,
          itemRemoved: analyticsProducts.itemRemoved || 0,
          productPriceRemove: analyticsProducts.productPriceRemove || 0,
          productQuantityRemove: analyticsProducts.productQuantityRemove || 0,
          MIAMPriceRemove: analyticsProducts.MIAMPriceRemove || 0,
          ExtrasPriceRemove: analyticsProducts.ExtrasPriceRemove || 0,
          ComboPriceRemove: analyticsProducts.ComboPriceRemove || 0,
          promoApplied: analyticsProducts.promoApplied || 0,
          promoError: analyticsProducts.promoError || 0,
          curbsideSelected: analyticsProducts.curbsideSelected || 0,
          warningMessageEvent: analyticsProducts.warningMessageEvent || 0,
          placeOrderEvent: analyticsProducts.placeOrderEvent || 0,
          checkoutstarted: analyticsProducts.checkoutstarted || 0,
          bagViewed: analyticsProducts.bagViewed || 0,
          productPriceView: analyticsProducts.productPriceView || 0,
          productQuantityView: analyticsProducts.productQuantityView || 0,
          MIAMPriceView: analyticsProducts.MIAMPriceView || 0,
          ExtrasPriceView: analyticsProducts.ExtrasPriceView || 0,
          ComboPriceView: analyticsProducts.ComboPriceView || 0,
          itemViewed: analyticsProducts.itemViewed || 0,
          orderFavorited: analyticsProducts.orderFavorited || 0,
          promoView: analyticsProducts.promoView || 0,
          clicktocall: analyticsProducts.clicktocall || 0,
          storeGetDirections: analyticsProducts.storeGetDirections || 0,
          purchase: analyticsProducts.purchase || 0,
          certificateDiscount: analyticsProducts.certificateDiscount || 0,
          discount: analyticsProducts.discount || 0,
          tax: analyticsProducts.tax || '',
          certsApplied: analyticsProducts.certsApplied || 0,
          curbsideDelivery: analyticsProducts.curbsideDelivery || 0,
          rewardApplied: analyticsProducts.rewardApplied || 0,
          MIAMPricePurchase: analyticsProducts.MIAMPricePurchase || 0,
          ExtrasPricePurchase: analyticsProducts.ExtrasPricePurchase || 0,
          ComboPricePurchase: analyticsProducts.ComboPricePurchase || 0,
          sandwichArtistTipAmount: analyticsProducts.sandwichArtistTipAmount || 0,
          subTotal: analyticsProducts.subTotal || 0,
          orderTax: analyticsProducts.orderTax || 0,
          deliveryFees: analyticsProducts.deliveryFees || '',
          driverTipAmount: analyticsProducts.driverTipAmount || '',

          rewardItemRemoved: analyticsProducts.rewardItemRemoved || 0,
          dealRemoved: analyticsProducts.dealRemoved || 0,
          loggedout: analyticsProducts.loggedout || 0,

          attemptAccountToDelete :analyticsProducts.attemptAccountToDelete || 0,
          utensilSelected : analyticsProducts.utensilSelected || 0,
          viewRewards: analyticsProducts.viewRewards || 0, 
          deliverySearch:analyticsProducts.deliverySearch || 0,
          restaurantSelected: analyticsProducts.restaurantSelected || 0,
          delivery: analyticsProducts.delivery || 0,
          rewardPoints: analyticsProducts.rewardPoints || 0,
          rewardPrice: analyticsProducts.rewardPrice || 0,
          dealRedeemed: analyticsProducts.dealRedeemed || 0,
          orderDetails: window.orderViewModel.orderStatusClicked && props.pageName == "order-confirmation" ? 1 : 0,
          quickAddonSelected: analyticsProducts.quickAddonSelected || 0,
          comboOrdered: analyticsProducts.comboOrdered || 0,
          quickAddonOrdered: analyticsProducts.quickAddonOrdered || 0,
          comboSelected: analyticsProducts.comboSelected || 0,
        },
        cartID: cartID || analyticsProducts.cartID || '',
        totalCards: totalCards || 0,
        onCardClick: selectedCard || 0,
        DBFavoritesOrBestsellers: favOrBestSeller,
        productCategory: analyticsProducts.productCategory || '',
        products: analyticsProducts.products || [],
        removedProduct: analyticsProducts.removedProduct || [],
        warningMessage: analyticsProducts.warningMessage || '',
        errorMessage: analyticsProducts.errorMessage || '',
        parentErrorCode: analyticsProducts.parentErrorCode || '',
        childErrorCode: analyticsProducts.childErrorCode || '',
        bannerMessage: analyticsProducts.bannerMessage || '',
        orderSourceType: analyticsProducts.orderSourceType || '',
        offerID: analyticsProducts.offerID || '',
        offerDesc: analyticsProducts.offerDesc || '',
        itemFavoritedOrderID: analyticsProducts.itemFavoritedOrderID || '',
        itemFavoritedProductID: analyticsProducts.itemFavoritedProductID || '',
        transactionID: analyticsProducts.transactionID || '',
        ConsumerPrice: analyticsProducts.ConsumerPrice || '',
        itemUnits: analyticsProducts.itemUnits || '',
        itemPrice: analyticsProducts.itemPrice || '',
        billingZip: analyticsProducts.billingZip || '',
        paymentMethod: analyticsProducts.paymentMethod || '',
        orderValue: analyticsProducts.orderValue || '',
        currency: analyticsProducts.currency || '',
        purchaseID: analyticsProducts.purchaseID || '',
        certificateApplied: analyticsProducts.certificateApplied || '',
        certDiscAmt: analyticsProducts.certDiscAmt || '',
        sandwichArtistTipPercent: analyticsProducts.sandwichArtistTipPercent || '',
        rewardName: analyticsProducts.rewardName || '',
        utensilName : analyticsProducts.utensilName || '',
        // products: {
        //   orderSource: analyticsProducts.orderSource || FALLBACK_NA,
        //   productDetails: analyticsProducts.productDetails|| FALLBACK_NA,
        //   toastBanner: analyticsProducts.toastBanner|| FALLBACK_NA,
        //   isModalOpen:analyticsProducts.isModalOpen || false,
        //   isModalClose:analyticsProducts.isModalClose || false,
        //   errorModalName: analyticsProducts.errorModalName || FALLBACK_NA,
        //   warningMessage: analyticsProducts.warningMessage || FALLBACK_NA,
        //   errorMessage: analyticsProducts.errorMessage || FALLBACK_NA,
        //   buttonName: analyticsProducts.buttonName || FALLBACK_NA,
        //   ...analyticsProducts
        // },
        loginStatus: props.loggedIn ? true : false,
        storeCountry: orderViewModel
          ? orderViewModel.store.storeCountry || CONSTANTS.fallback_country
          : FALLBACK_NA,
        storeRegion: orderViewModel && orderViewModel.store.storeCountry == "PR"
          ? orderViewModel.store.storeCountry || CONSTANTS.fallback_country
          : FALLBACK_NA,
        orderType: orderViewModel.orderType
          ? orderViewModel.orderType
          : FALLBACK_NA,
        storeID: orderViewModel
          ? orderViewModel.store.id || orderViewModel.store.StoreId || FALLBACK_NA
          : FALLBACK_NA,
        storeLanguage: orderViewModel.store.languageCode
          ? orderViewModel.store.languageCode.substring(0, 2)
          : FALLBACK_NA,
        guestCountry: orderViewModel
          ? orderViewModel.Analytics.guestCountry || analyticsProducts.guestCountry || CONSTANTS.fallback_country || FALLBACK_NA
          : FALLBACK_NA,
        loyaltyActiveFlag:
          orderViewModel.LoyaltyOption != null ? orderViewModel.LoyaltyOption : FALLBACK_NA,
        loyaltyID: orderViewModel.hasLoggedIn ? dtmLoyaltyId : FALLBACK_NA,
        emailOptIn:
          orderViewModel.EmailOptInProfile != null ? orderViewModel.EmailOptInProfile : FALLBACK_NA,
        localTime: getLocalTime(),
        tierLevel: orderViewModel.hasLoggedIn ? orderViewModel.Analytics.tierLevel : FALLBACK_NA,
        user: {
          promo: analyticsProducts.promo || [],
        }
      };
    }
  }, [props, orderViewModel, analyticsProducts]);

  useEffect(() => {
    const pagesArray = [
      RESTAURANT,
      MENU,
      ORDER_MANAGEMENT,
      CUSTOMIZER,
      ORDER_CONFIRMATION,
      PROFILE,
      DELIVERY_LOCATOR,
    ];
    if (matchUrl(pagesArray)) {
      if (attemptTracter.length > 0) {
        reactAttemptTrack(attemptTracter);
      }
    }
  }, [attemptTracter]);
};

export default useAnalytics;

export const CONSTANTS = {
  fallback_country: FALLBACK_NA,
};

export const UTIL_FUNCTIONS = {
  getScreenBreakpoint: getScreenBreakpoint,
};
