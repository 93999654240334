import { CONFIRMATION_MODAL_UPSELL, FALLBACK_NA } from '../../constants/AnalyticsConstants';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { matchUrl } from '../../Utils/componentUtils';
import { REGULAR_QUICK_ADD_ONS, PDP_UPSELL_CAROUSEL, QUICK_ADD_ON, QUICK_ADD_ON_FR, REGULAR,cultureLanguage, QUICK_ADD_ONS, CART_QUICKADDONS_SOURCE, QUICK_ADD_ON_ES } from '../../constants/App';
import { multipleAndCheck, ternaryCheck } from '../../Utils/WindowUtils';
import { MAKE_IT_A_COMBO } from '../../constants/translations';

const pagesArray = ['restaurant', 'menu', 'order-management', 'order-confirmation', 'profile'];
if (matchUrl(pagesArray)) {
  (window as any).fwhtrk_new = {
    page: {
      name: '',
      hostName: '',
      local: '',
      componentTitle: '',
      clickActionName: '',
      virtualName: '',
      type: '',
    },
    user: {
      geoSelected: FALLBACK_NA,
      guestID: FALLBACK_NA,
      loyaltyID: FALLBACK_NA,
      uxRendered: FALLBACK_NA,
      loyaltyActiveFlag: FALLBACK_NA,
      emailOptIn: FALLBACK_NA,
      experience: FALLBACK_NA,
    },
    loginStatus: 'logged-out',
    storeCountry: FALLBACK_NA,
    storeID: FALLBACK_NA,
    storeLanguage: FALLBACK_NA,
    guestCountry: FALLBACK_NA,
    orderType: FALLBACK_NA,
    localTime: FALLBACK_NA,
  };
}

declare global {
  interface Window {
    _satellite?: any;
  }
}

export const attemptTrack = function (event: any) {
  if (window._satellite) {
    window._satellite.track(event);
  }
};

export interface AttemptTrack {
  trackID?: string;
  name: string;
  isClickable?: boolean;
}

export const reactAttemptTrack = (attempts: AttemptTrack[]) => {
  attempts.forEach(attempt => {
    const { trackID = '', name, isClickable = false } = attempt;
    if (trackID) {
      attemptTrack(trackID);
    } else {
      const click = isClickable ? '_click_action' : '';
      attemptTrack(`react_${name}${click}`);
    }
  });
};

const sizeNames = ['footlong', '6"', "6''", 'footlong pro', '12 po', '6 po', 'fl pro'];

export const getSizeName = (productName = '') => {
  const sizename = sizeNames.filter(v => {
    return productName.toLowerCase().indexOf(v) >= 0;
  });
  return sizename.length > 0 ? sizename[0] : 'n/a';
};

export const ingredientList = (response: any, categoryName: string) => {
  const ingredients: any = {};
  const ingredientsData = [
    'Bread',
    'Cheese',
    'Vegetable',
    'Sauce',
    'Seasonings',
    'Extra',
    'Egg',
    'Pasta',
    'Protein',
    'Rice',
    'BreadCustomizations',
    'sideOfToast',
    'flavor',
  ];
    response?.options?.forEach((list: any) => {
      const optionOject = {
        optionName: list.optionName.split('+')[0],
        isAvailable: list.isAvailable ? list.isAvailable : '',
        price: list.price ? list.price : 0,
      }
      const data: any = [optionOject,];
      if (list.modifierGrpId in ingredients) {
        ingredients[list.modifierGrpId] = [
          ...ingredients[list.modifierGrpId],
          optionOject,
        ];  
      }
      else if (categoryName === 'Pasta' && list.modifierGrpId === 'Bread') {
        ingredients['sideOfToast'] = [...data];
      } else {
        const value = list.modifierGrpId;
        ingredients[value] = [...data];
      }     
    });

  for (const ingredient of ingredientsData) {
    if (!(ingredient in ingredients)) {
      ingredients[ingredient] = [];
    }
  }
  return ingredients;
};

export const comboList = (response: any) => {
  const dataList: any = [];
      const itemsToMap = response?.combo?.items || response?.combo?.comboItems 
      itemsToMap?.map((list: any,index:number) => {
        const optionList = _get(list.options, ['0'], {});
        if (_isEmpty(optionList)) {
          dataList.push({
            isDrink: index == 0 ? 1 : 0,
            isSide: index > 0 ? 1 : 0,
            productName: list.productName || '',
            productId: list.productId || '',
            upcharge: list.upcharge || 0,
            isAvailable: list.isAvailable || '',
          });
        } else {
          dataList.push({
            isDrink: index == 0 ? 1 : 0,
            isSide: index > 0 ? 1 : 0,
            productName: optionList.optionName || '',
            productId: list.productId || '',
            upcharge: list.upcharge || 0,
            isAvailable: optionList.isAvailable || '',
          });
        }
      });
    response?.meal?.components?.map((list: any, index: number) => {
      dataList.push({
        isDrink: index == 0 ? 1 : 0,
        isSide: index > 0 ? 1 : 0,
        productName: list.name ? list.name : '',
        upcharge: list.upCharge ? list.upCharge : 0,
        isAvailable: list.isAvailable ? list.isAvailable : '',
      });
    });
  return dataList;
};

const getAdditionalItem = (response: any, comboPrice: any, upchargePrice: any, comboResult: any) => {
  const comboName = (response?.combo?.comboName) || response.mealText
  return response.combo || response.meal
    ? {
      source: response.analytics?.comboOriginSource || 'n/a',
      comboName:cultureLanguage.includes(window.orderViewModel.scLanguageCode) ? MAKE_IT_A_COMBO : comboName,
      price: comboPrice + upchargePrice.reduce((a: any, b: any) => a + b, 0),
      comboId: (response.combo?.comboId) || 'n/a',
      options: comboResult,
    }
    : {}
}
export const productString = (
  response: any,
  productDetails: any,
  unavialableItem: string,
  bagSource: string,
  type = "",
  upsellBaseProductName?: string,
  isComboLiteUpsell?:boolean
) => {
  const {categoryName, productName, price, quantity, size, productId, badgeName } = productDetails;
  const result = [];
  if (response) {
    const quickAddonCategory = [QUICK_ADD_ON, QUICK_ADD_ON_FR, QUICK_ADD_ON_ES];
    const isUpsell =  quickAddonCategory.includes(categoryName?.toLowerCase());
    let upsellAttribute = 'n/a'
    if(type == REGULAR && isUpsell) upsellAttribute = PDP_UPSELL_CAROUSEL;
    if((isComboLiteUpsell || response.analytics?.productOriginSource == REGULAR_QUICK_ADD_ONS) && isUpsell) upsellAttribute = CONFIRMATION_MODAL_UPSELL;
    if((bagSource == CART_QUICKADDONS_SOURCE || response.analytics?.productOriginSource == QUICK_ADD_ONS ) && isUpsell) upsellAttribute = CART_QUICKADDONS_SOURCE;
    const comboResult = comboList(response);
    const upchargePrice = 
      comboResult && comboList.length > 0
        ? comboResult.map((option: any) => option.upcharge)
        : [0, 0];
    const comboPrice = response.combo
      ? response.combo.bundledPrice
      : response.meal?.mealCurrentPrice;
    const subCategory = ternaryCheck(!!response.analytics?.subCategory , response.analytics?.subCategory , response.subCategory);
    const data = {
      productName: productName, 
      productId: productId?.toString(),
      badgeName: badgeName || 'n/a',
      productCategory: categoryName,
      subCategory: subCategory || 'n/a',
      productPrice: price ?? 'n/a',
      breadSize: getSizeName(size),
      unavialableItem: unavialableItem || 'n/a',
      productQuantity: quantity || 'n/a',
      orderSourceType: type,
      addToBagSource: bagSource || '',
      ingredients: ingredientList(response, categoryName),
      isUpsell: ternaryCheck(multipleAndCheck((type == REGULAR || isComboLiteUpsell), isUpsell), 1, 0),
      upsellBaseProduct: upsellBaseProductName || 'n/a',
      upsellProductAttribute: upsellAttribute,
      additionalItem:getAdditionalItem(response,comboPrice,upchargePrice,comboResult),
    };
    result.push(data);
  }
  return result;
};

export const pageName = (name: string) => {
  switch (name) {
    case 'menu':
      return 'menu:overview';
    case 'products':
      return 'category page';
    case 'order-management':
      return 'bag';
    case 'customizer':
      return 'product details';
    case 'order-confirmation':
      return window.orderViewModel.orderStatusClicked ? 'order details' : 'order confirmation';
    case 'profile':
      return 'profile';
    case 'my-favorites':
      return 'profile:my-favorites';
    case 'requestmissingpoints':
      return 'profile:requestmissingpoints';
    case 'paymentmethods':
      return 'profile:paymentmethods';
    case 'recent-orders':
      return 'profile:recent-orders';
    case 'contactinfo':
      return 'profile:contactinfo';
    case 'deliverylocator':
      return 'deliverylocator';
    default:
      return '';
  }
};

export const pageSection = (name: string) => {
  switch (name) {
    case 'menu':
      return 'menu';
    case 'products':
      return 'category';
    case 'order-management':
      return 'bag';
    case 'customizer':
      return 'product details';
    case 'order-confirmation':
      return window.orderViewModel.orderStatusClicked ? 'order details' : 'order confirm';
    case 'profile':
    case 'contactinfo':
    case 'paymentmethods':
    case 'recent-orders':
    case 'my-favorites':
    case 'requestmissingpoints':
      return 'account';
    case 'deliverylocator':
      return 'locator';
    default:
      return '';
  }
};

export const getOrderTypeByFulfillmentType = (fulfillmentType:string) =>{
  switch (fulfillmentType) {
      case "pickup":
          return "In-Store Pickup";
      case "delivery":
          return "Delivery";
      case "curbside":
          return "Curbside Pickup";
      default:
          return "n/a";
  }
}