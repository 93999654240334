import components from './ComponentRegistry';

/**
 * Get a component from CMS
 * @param  {String} resourceKey Unique key defined
 */
export default resourceKey => {
  const map = { ...components };
  return map[resourceKey];
};
