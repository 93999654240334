import React from 'react';

// Interface for Heading component to declare types for its props
interface HeadingType {
  title?: string | React.JSX.Element | number;
  className?: string;
  style?: React.CSSProperties;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span';
  id?: string;
  tabIndex?: number;
  testAutoId?: string;
  ariaLabel?: string;
  ariaLive?: 'assertive' | 'off' | 'polite';
}
/**
 * Heading component
 * @param param0.level level can be any of these 'h1' to 'h5', 'p','span'
 * @param param0.title text
 * @param param0.className custom class name
 * @param param0.id custom id name
 * @param param0.tabIndex for accessbility
 * @returns react heading component
 */
const Heading: React.FC<HeadingType> = ({ level = 'h1', className, style, title, id, testAutoId, ariaLabel, ariaLive }) => {
  const Tag = level;
  const ariaProps = {
    ...(ariaLabel && { 'aria-label': ariaLabel }),
    ...(ariaLive && { 'aria-live': ariaLive }),
  };  
  return <Tag className={className} style={style} id={id} data-testid={testAutoId} data-testauto-id={testAutoId} {...ariaProps}>{title}</Tag>;
};

export default Heading;
