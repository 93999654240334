import React, { useEffect } from 'react';
import { WARNING_MESSAGE } from '../../../constants/AnalyticsConstants';
import { FIND_ANOTHER_RESTAURANT_BTN, OK_TEXT_BTN } from '../../../constants/ContextConstants';
import useStore from '../../../store/useStore';
import Button from '../../Atoms/Button/Button';
import Link from '../../Atoms/Link/Link';
import { CreateCartInterface } from '../../Interfaces/CreateCartErrorsInterface';
import Modal from '../Modal/Modal';

const CreateCartErrors: React.FC<CreateCartInterface> = ({
  showModal,
  modalData,
  closePopup,
  showClose,
  okText,
  name,
}) => {
  const { setAnalyticsProducts, setAttemptTracker, setContextLoader } = useStore();
  useEffect(() => {
    setAnalyticsProducts({
      warningMessage: modalData.message,
      section: name,
      warningMessageUI :1,
      virtualName: 'select a new restaurant modal'
    });
    setAttemptTracker([{ name: WARNING_MESSAGE }]);
  }, []);
  const onClick = ( e: React.SyntheticEvent ) => {
    e.stopPropagation();
    setContextLoader({
      buttonId: FIND_ANOTHER_RESTAURANT_BTN,
      isLoading: true,
    });
    setAnalyticsProducts({
      clickActionName: 'find another restaurant',
      virtualName: 'select a new restaurant modal'
    });
    setAttemptTracker([{ name: 'modal_cta', isClickable: true }]);
  };

  return (
    <Modal
      isShown={showModal}
      title={modalData.title}
      description={modalData.message}
      onButtonClick={closePopup}
      isClosebtn={showClose}
    >
      {modalData.url ? (
        <Link
          text={modalData.buttonText}
          href={modalData.url}
          onClick={e => onClick(e)}
          className="popup__button"
          testAutoId={FIND_ANOTHER_RESTAURANT_BTN}
        />
      ) : (
        <Button
          className="popup__button"
          testAutoId={OK_TEXT_BTN}
          onClick={() => {
            closePopup();
            setContextLoader({
              buttonId: OK_TEXT_BTN,
              isLoading: true,
            });
          }} >
          {okText}
        </Button>
      )}
    </Modal>
  );
};

export default CreateCartErrors;
