import React from 'react';
import { createRoot } from 'react-dom/client';

import 'regenerator-runtime/runtime';
import './index.scss';
import App from './App';
import { wrapper } from './QueryClient';
const container = document.getElementById('root-react-pr');
const root = createRoot(container as HTMLElement);
root.render(
  wrapper(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  ),
);
