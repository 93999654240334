import React from 'react';
const RewardStatus = React.lazy(() => import('./components/Molecules/RewardStatus/RewardStatus'));
const RewardsPage = React.lazy(() => import('./components/Molecules/RewardsPage/RewardsPage'));
const RewardCards = React.lazy(() => import('./components/Molecules/RewardCards/RewardCards'))
const OrderManagement = React.lazy(() => import( './components/Templates/OrderManagement/OrderManagement'));
const FavoriteOrBestseller = React.lazy(() => import('./components/Molecules/FavoriteOrBestseller/FavoriteOrBestseller'));
const Menu = React.lazy(() => import('./components/Molecules/Menu/Menu'));
const MenuDeliveryDescription = React.lazy(() => import('./components/Molecules/Menu/MenuDeliveryDescription'));
const CategoryProductList = React.lazy(() => import('./components/Templates/CategoryProductList/CategoryProductList'));
const PdpPage = React.lazy(() => import('./components/Organisms/PDPPage'));
const UpdatePhoneNumber = React.lazy(() => import('./components/Molecules/UpdatePhoneNumber/UpdatePhoneNumber'));
const DealsPromotionsAndDashboardNotification = React.lazy(() => import("../src/components/DealsPromotionsAndNotification/DealsPromotionsAndDashboardNotification"));
const LoyaltyAcquisitionModal = React.lazy(() => import("../src/components/Molecules/LoyaltyAcquisitionModal/LoyaltyAcquisitionModal"));
const JoinMVPRewards = React.lazy(() => import("../src/components/Molecules/JoinMVPRewards/JoinMVPRewards"));
const NationalDeals = React.lazy(() => import('./components/Templates/RewardsAndDeals/NationalDeals'));
const QuickAddOn = React.lazy(() => import('./components/Molecules/QuickAddOn/QuickAddOn'));
const OrderConfirmation = React.lazy(() => import('./components/Templates/OrderConfirmation/OrderConfirmation'));
const Profile = React.lazy(() => import('./components/Templates/Profile/Profile'));
const PaymentMethods = React.lazy(() => import('./components/Templates/PaymentMethods/PaymentMethods'));
const RecentOrdersList = React.lazy(() => import('./components/Organisms/RecentOrdersList/RecentOrdersList'));
const CustomerProfile = React.lazy(() => import('./components/Templates/CustomerProfile/CustomerProfile'));
const MissingRewardsPoints = React.lazy(() => import('./components/Templates/RewardsMissingPoints/RewardsMissingPoints'));
const DefaultHeader = React.lazy(() => import('./components/Molecules/DefaultHeader/DefaultHeader'));
const NavBar = React.lazy(() => import('./components/Organisms/NavBar/NavBar'));
const FooterLastColumn = React.lazy(() => import('./components/Templates/Footer/FooterLastColumn/FooterLastColumn'));
const Footer = React.lazy(() => import('./components/Templates/Footer/Footer'));
const DeliveryLocator = React.lazy(() => import('./components/Organisms/DeliveryLocator/DeliveryLocator'));

const components = {
  //Categories: BestSellar,
  Favorites: [FavoriteOrBestseller, { isFavourite: true, siteName: siteName, okText: okText, loadCartBag: window.loadCartBag, orderViewModel: orderViewModel, shouldRenderCustomSlider: true }],
  Categories: [Menu, { siteName: siteName, okText: okText, orderViewModel: orderViewModel, subwayScript: subwayScript }],
  Bestseller: [FavoriteOrBestseller, { siteName: siteName, orderViewModel: orderViewModel, shouldRenderCustomSlider: true }],
  DeliveryFeeDescription: [MenuDeliveryDescription, { orderViewModel: orderViewModel }],
  Reward: [RewardsPage, { siteName: siteName, analytics: analytics, engageApptentiveEvent: engageApptentiveEvent, }],
  RewardsDashboard: [RewardStatus, { siteName: siteName, analytics: analytics }],
  ProductsList: [CategoryProductList, { siteName: siteName, okText: okText,orderViewModel: orderViewModel }],
  OrderManagement: [OrderManagement, { siteName: siteName, okText: okText, orderViewModel: orderViewModel, subwayScript: subwayScript, loadCartBag: window.loadCartBag }],
  QuickAddOns: [QuickAddOn, { siteName: siteName, orderViewModel: orderViewModel }],
  PDP: [PdpPage, { analytics: analytics, siteName: siteName, addToBagSuccessMsg: AddToBagSuccessMsg, orderViewModel: orderViewModel, subwayScript: subwayScript }],
  // MakeItAMeal: [MIAMComponent, { siteName: siteName, addToBagSuccessMsg: AddToBagSuccessMsg }],
  UpdatePhone: [UpdatePhoneNumber, { siteName: siteName, orderViewModel: orderViewModel, analytics: analytics }],
  AvailableReward: [RewardCards, { siteName: siteName, getLocalTime: getLocalTime }],
  OrderConfirmation: [OrderConfirmation, { siteName: siteName, orderViewModel: orderViewModel, okText: okText }],
  ProfileFavorites: [FavoriteOrBestseller, { isShowSkeleton: true, siteName: siteName, orderViewModel: orderViewModel, okText: okText, loadCartBag: window.loadCartBag, isFavourite: true }],
  DashboardNotification: [DealsPromotionsAndDashboardNotification, { orderViewModel: orderViewModel, analytics: analytics, siteName: siteName, getLocalTime: getLocalTime, ShopPromotionFlag: ShopPromotionFlag, modalClose: modalClose, modalShowId: modalShowId, subwayScript: subwayScript, loyaltyObject: loyaltyObject, Congrats: Congrats }],
  LoyaltyAcquisitionModal: [LoyaltyAcquisitionModal, { siteName: siteName }],
  JoinMVPRewards: [JoinMVPRewards, { siteName: siteName }],
  DealsFilter: [NationalDeals, { siteName: siteName, orderViewModel: orderViewModel, analytics: analytics, loadCartBag: window.loadCartBag }],
  NavigationContentBlockRendering: [Profile, { orderViewModel: orderViewModel, siteName: siteName, engageApptentiveEvent: engageApptentiveEvent }],
  PaymentMethods: [PaymentMethods, { siteName: siteName, orderViewModel: orderViewModel, analytics: analytics, okText: okText, engageApptentiveEvent: engageApptentiveEvent }],
  RecentOrderPage: [RecentOrdersList, { siteName: siteName, orderViewModel: orderViewModel, analytics: analytics, loadCartBag: window.loadCartBag, okText: okText }],
  ContactDetails: [CustomerProfile, { siteName: siteName }],
  MissingRewardsPoints: [MissingRewardsPoints, { siteName: siteName }],
  DefaultHeaderRendering: [DefaultHeader, { siteName: siteName, orderViewModel: orderViewModel, subwayScript: subwayScript,analytics:analytics }],
  HeaderBlock: [NavBar, { siteName: siteName, orderViewModel: orderViewModel, engageApptentiveEvent: engageApptentiveEvent,analytics:analytics }],
  FooterLastColumn: [FooterLastColumn, { siteName: siteName }],
  GlobalFooter: [Footer, { siteName: siteName,analytics:analytics }],
  DeliveryLocator: [DeliveryLocator, { siteName: siteName, orderViewModel: orderViewModel, engageApptentiveEvent: engageApptentiveEvent }]
};

export default { ...components };
