import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './Modal.scss';
import SvgIconClose from '../../../assets/RemoteOrder/icons/CloseWithoutCircle.svg';
import SodiumWarning from '../../../assets/RemoteOrder/icons/SodiumWarning.svg';
import Button from '../../Atoms/Button/Button';
import { addFocusListener, onTabEsc } from '../../../Utils/componentUtils';
import ModalProps from '../../Interfaces/ModalInterface';
import Heading from '../../Atoms/Heading/Heading';
import { createPortal } from 'react-dom';

const Modal: React.FC<ModalProps> = ({
  isShown = true,
  onButtonClick,
  title,
  isClosebtn = false,
  showIcon = false,
  description,
  testId,
  className = 'modalData',
  modalTestIdIdentifier = 'common',
  modalParentClass = '',
  componentParentClass = '',
  isModalBottomSlide = false,
  showCurvedTopModal = false,
  isCloseModalOnEscape = true,
  ...props
}) => {
  const [activeElement, setActiveElement] = useState<any>('');
  const [modalSlideToggleUp, setModalSlideToggleUp] = useState<boolean>(isModalBottomSlide);
  const modalRef = useRef<HTMLDivElement>(null);
  const firstFocusableElementRef = useRef<any>(null);
  const lastFocusableElementRef = useRef<any>(null);
  const focusTrapId = document.getElementById('react-popup') as HTMLElement;

  useEffect(() => {
    if (isShown) {
      if(isCloseModalOnEscape){
        onTabEsc(onButtonClick);
      }      
      addFocusListener(modalRef, firstFocusableElementRef, lastFocusableElementRef);
    }
    const reactPopup = document.getElementById('react-popup') as HTMLElement;
    const modalPopup = reactPopup?.querySelector('.modalPopup');
    const focusModalPopup = reactPopup?.querySelector('.focus-modal-popup');
    const stickTo = document.getElementById('stick-to') as HTMLElement;
    const headerSticky = stickTo?.querySelector('.header-sticky');
    const homePageNew = stickTo?.querySelector('#homePageNew');
    const componentFooter = stickTo?.querySelector('.component_footer');
    const htmlElement = document.querySelector('.cms')  as HTMLElement;

    if (modalPopup && focusModalPopup && headerSticky && homePageNew && componentFooter) {
      focusModalPopup.setAttribute('aria-hidden', isShown ? 'false' : 'true');
      headerSticky.setAttribute('aria-hidden', isShown ? 'true' : 'false');
      homePageNew.setAttribute('aria-hidden', isShown ? 'true' : 'false');
      componentFooter.setAttribute('aria-hidden', isShown ? 'true' : 'false');
      htmlElement?.style?.setProperty('overflow-y', 'hidden');
    }
    return () => {
      if (focusModalPopup && headerSticky && homePageNew && componentFooter) {
        focusModalPopup.removeAttribute('aria-hidden');
        headerSticky.removeAttribute('aria-hidden');
        homePageNew.removeAttribute('aria-hidden');
        componentFooter.removeAttribute('aria-hidden');
      }
      htmlElement?.style?.setProperty('overflow-y', 'initial');
    };
  }, [isShown]);

  useLayoutEffect(() => {
    setActiveElement(document.activeElement);
  }, [])

  const modalSlideClass = isModalBottomSlide ? "modalBottomSlide" : "";
  const slideClass = modalSlideToggleUp ? 'slideUp' : 'slideDown';
  const curvedTopClass = showCurvedTopModal ? 'showCurvedTopModal' : '';
  const combinedClassName = `modalPopup__container ${slideClass} ${curvedTopClass} ${className}`;

  return isShown
    ? createPortal(
      <div data-testid={testId} id="modal" className={`focus-modal-popup ${componentParentClass}`} ref={modalRef}>
        <div className={`modalPopup ${modalParentClass} ${modalSlideClass} `}>
          <div className="modalPopup__wrapper">
            <dialog
              className={combinedClassName}
              aria-labelledby="title__labelledby"
              aria-modal="true"
              role="alertdialog"
              data-modal-background-click="disabled" //for testing
              open
            >
              <div className="modal_header">
                {isClosebtn &&
                  <Button
                    testAutoId={'modal-popup-closeBtn'}
                    className="modalPopup__close"
                    onClick={() => {
                      if (activeElement) {
                        activeElement?.focus?.();
                      }
                      if (modalSlideToggleUp) {
                        setModalSlideToggleUp(!modalSlideToggleUp)
                        setTimeout(() => {
                          onButtonClick()
                        }, 500);
                      }
                      else {
                        onButtonClick()
                      }
                    }}
                    icon={<SvgIconClose />}
                    aria-label="close"
                  ></Button>
                }
                {(title || showIcon) && (
                  <div className="modalPopup__header">
                    {showIcon && <SodiumWarning />}
                    <Heading id="title__labelledby" className="modalPopup_title" testAutoId={`${modalTestIdIdentifier}-modal-title`} level="h2" title={title} />
                  </div>
                )}
              </div>
              {description && <div className="modalPopup__body" data-testauto-id={`${modalTestIdIdentifier}-modal-description`}>{description}</div>}
              <div className="modalPopup__button">{props.children}</div>
            </dialog>
          </div>
        </div>
      </div>, focusTrapId,
    )
    : null

};

export default Modal;