/**
 * Fallback continue button
 * @memberof constants/AnalyticsConstants
 */
export const CONTINUE_BTN = 'continueBtn';

/**
 * Fallback keep shooping
 * @memberof constants/AnalyticsConstants
 */
export const KEEP_SHOPPING_BTN = 'keep-shopping-btn';

/**
 * Fallback start order 
 * @memberof constants/AnalyticsConstants
 */
export const START_ORDER_BTN = 'start-order-btn';

/**
 * Fallback start new order
 * @memberof constants/AnalyticsConstants
 */
export const START_NEW_ORDER_BTN = 'start-new-order-btn';

/**
 * Fallback return to checkout
 * @memberof constants/AnalyticsConstants
 */
export const RETURN_TO_CHECKOUT = 'return-to-checkout';

/**
 * Fallback switch location
 * @memberof constants/AnalyticsConstants
 */
export const SWITCH_LOCATION_BTN = 'switch-location-btn';

/**
 * Fallback switch to pickup
 * @memberof constants/AnalyticsConstants
 */
export const SWITCH_TO_PICKUP_BTN = 'switch-to-pickup-btn';

/**
 * Fallback cancel delivery
 * @memberof constants/AnalyticsConstants
 */
export const CANCEL_DELIVERY_BTN = 'cancel-delivery-btn';

/**
 * Fallback find another restaurant
 * @memberof constants/AnalyticsConstants
 */
export const FIND_ANOTHER_RESTAURANT_BTN = 'findAnotherRestaurantBtn';

/**
 * Fallback find new location cancel
 * @memberof constants/AnalyticsConstants
 */
export const FIND_NEW_LOCATION_CANCEL_BTN = 'find-new-location-cancel-btn';

/**
 * Fallback find new location accept
 * @memberof constants/AnalyticsConstants
 */
export const FIND_NEW_LOCATION_ACCEPT_BTN = 'find-new-location-accept-btn';

/**
 * Fallback delivery to this address button
 * @memberof constants/AnalyticsConstants
 */
export const DELIVER_TO_THIS_ADDRESS_BUTTON = 'deliver-to-this-address-button';

/**
 * Fallback upsellpopup button
 * @memberof constants/AnalyticsConstants
 */
export const UPSELLPOPUP_BTN = 'upsellPopup__btn';

/**
 * Fallback upsellpopup button
 * @memberof constants/AnalyticsConstants
 */
export const NOTHANKS_BTN = 'upsellPopup__noThanksBtn';

/**
 * Fallback prouct confirmation view bag checkout
 * @memberof constants/AnalyticsConstants
 */
export const PRODUCT_CONFIRMATION_VIEW_BAG_CHECKOUT = 'product_confirmation_view_bag_checkout';

/**
 * Fallback popup button ok
 * @memberof constants/AnalyticsConstants
 */
export const POPUP_BTN_OK = 'popup-btn-ok';

/**
 * Fallback clear my cart
 * @memberof constants/AnalyticsConstants
 */
export const CLEAR_MYCART_BTN = 'clearMyCart-btn';

/**
 * Fallback remove item from cart
 * @memberof constants/ContextConstants
 */
export const REMOVE_ITEM_FROM_CART_BTN = 'removeItemFromCart-btn';

/**
 * Fallback clear my cart
 * @memberof constants/ContextConstants
 */
export const CLEAR_MY_CART_BTN = 'clearMyCart-btn';

/**
 * Fallback clear my cart
 * @memberof constants/ContextConstants
 */
export const ORDER_SOMETHING_ELSE_BTN = 'orderSomethingElse';

/**
 * Fallback clear my cart
 * @memberof constants/ContextConstants
 */
export const OK_TEXT_BTN = 'okText';

/**
 * Fallback pdp add to cart
 * @memberof constants/ContextConstants
 */
export const PDP_ADD_TO_CART = 'pdp-add-to-cart';

/**
 * Fallback maim continue
 * @memberof constants/ContextConstants
 */
export const MIAM_CONTINUE = 'miam-continue';

/**
 * Fallback clear my cart
 * @memberof constants/ContextConstants
 */
export const OK_CTA_BTN = 'ok-cta';

/**
 * Fallback start order 
 * @memberof constants/AnalyticsConstants
 */
export const START_ORDER_LINK = 'start-order-link';

/**
 * Fallback dashboard notification link cta
 * @memberof constants/ContextConstants
 */
export const DASHBOARD_NOTIFICATION_LINK_CTA = 'dashboard-notification-link-cta';

/**
 * Fallback continue shopping
 * @memberof constants/ContextConstants
 */
export const CONTINUE_SHOPPING = 'continue-shopping';

/**
 * Fallback addOns plus icon
 * @memberof constants/ContextConstants
 */
export const ADDONS_PLUS_ICON = 'addons-plus-icon';

/**
 * Fallback remove favorite
 * @memberof constants/ContextConstants
 */
export const REMOVE_FAVORITE = 'removeFavorite';

/**
 * Fallback Error modal Ok cta
 * @memberof constants/ContextConstants
 */
export const ERROR_MODAL_OK_BTN = 'error-modal-ok-btn';

/**
 * Fallback DealsModal switch to pickup cta
 * @memberof constants/ContextConstants
 */
export const SWITCH_TO_PICKUP = 'switch-to-pickup';


/**
 * Fallback DealsModal keep delivery cta
 * @memberof constants/ContextConstants
 */
export const KEEP_DELIVERY = 'keep-delivery';


/**
 * Fallback CustomerProfile Save cta
 * @memberof constants/ContextConstants
 */
export const PROFILE_SAVE_CTA = 'profile-savecta';

/**
 * Fallback CustomerProfile Cancel cta
 * @memberof constants/ContextConstants
 */
export const PROFILE_CANCEL_CTA = 'profile-cancelcta';


/**
 *  Fallback CartPage curbside popup: Save button 
 *  @memberof constants/ContextConstants 
 */
export const SAVE_CURBSIDE = 'continuePayment-btn'

/**
 *  Fallback Payment method page: Remove button 
 *  @memberof constants/ContextConstants 
 */
export const REMOVE_BUTTON = 'remove-button'

/**
 *  Fallback Payment method page: transfer btn 
 *  @memberof constants/ContextConstants 
 */
export const TRANSFER_BUTTON_ID = 'transfer-btn-id'

/**
 *  Fallback Payment method page: Save Payment method button 
 *  @memberof constants/ContextConstants 
 */
export const SAVE_PAYMENT_METHOD_BUTTON = 'savePaymentMethod-btn'
    /**
     *  Fallback CartPage Apply Promocode CTA 
     *  @memberof constants/ContextConstants 
     */
export const APPLY_PROMOCODE_CTA = 'apply-promocode-btn'

        /**
     *  Fallback CartPage Apply Promocode CTA 
     *  @memberof constants/ContextConstants 
     */
export const ORDER_PROMOCLOSE_CTA = 'order-promoClose'


/**
 *  Fallback CartPage Rewards Missing points page: Submit button 
 *  @memberof constants/ContextConstants 
 */
export const REWARDS_MISSING_SUBMIT = "rewardsMissing-submit"
/**
 *  Fallback CartPage Guest: Login/sinup
 *  @memberof constants/ContextConstants 
 */
export const LOGIN_CONTINUE = 'loginContinue';

/**
 *  Fallback CartPage Guest: continue
 *  @memberof constants/ContextConstants 
 */
export const LOGGEDOUT_CONTINUE = 'loggedOutDdata-btn';

/**
 *  Fallback CartPage LoggedIn: continue
 *  @memberof constants/ContextConstants 
 */
export const ORDER_CHECKOUT_LOGIN = 'order-checkout';

/**
 *  Fallback Recent Order Page : ReOrder button
 *  @memberof constants/ContextConstants
 */
export const REORDER_CTA = "reorder-cta"

/**
 *  Fallback Recent Order Page : add to bag button
 *  @memberof constants/ContextConstants
 */
export const ADD_TO_BAG_CTA = "add-to-bag-cta"

/**
 *  Fallback ADA : Please wait
 *  @memberof constants/ContextConstants
 */
export const PLEASE_WAIT = "please wait"


/**
 * Fallback return to checkout
 * @memberof constants/AnalyticsConstants
 */
export const RETURN_TO_BAG = 'return-to-bag';

/**
 * Fallback Keep shopping button OrderManagement
 * @memberof constants/ContextConstants
 */
export const KEEP_SHOPPING_CTA = 'keepShopping';


/**
 * Fallback on confirmation page for curbside
 * @memberof constants/ContextConstants
 */
export const YES_IM_HERE = 'YES_IM_HERE';

/**
 * Fallback DealsModal view participating location
 * @memberof constants/ContextConstants
 */
export const VIEW_PARTICIPATING_LOCATION = 'view-participating-location';

/**
 * Fallback DealsModal continue without deal
 * @memberof constants/ContextConstants
 */
export const CONTINUE_WITHOUT_DEAL = 'continue-wtihout-deal';

/**
 * Fallback DealsModal continue without deal
 * @memberof constants/ContextConstants
 */
export const REMOVE_ITEM_CTA = 'remove-item-cta';