import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ButtonProps } from '../../../Interfaces/ButtonProps';
import './Button.scss';
import Lottie from 'lottie-react';
import { WHITE } from '../../../constants/App';
import useStore from '../../../store/useStore';
import { disableInteractiveElements } from '../../../Utils/WindowUtils';
import { getAnimationData } from '../../../Utils/InContextUtils';

/**
 * Button component
 * @param {String} className class name
 * @param {Boolean} selected flag for selected
 * @param {Boolean} isLoading flag for loading
 * @param {Boolean} isDisabled flag for disable
 * @param {String} icon icon
 * @returns Reusable Button component
 */

const Button: React.FC<ButtonProps> = ({
  className = 'button',
  selected,
  isLoading,
  isDisabled,
  icon,
  testAutoId = 'button',
  ...buttonProps
}) => {
  const { contextLoader, setContextLoader } = useStore();
  const [dynamicHeight, setDynamicHeight] =useState<string>('revert-layer');

 
  const styleObj = (element:any) => {
      if(element && element.offsetHeight) 
        return { height:element.offsetHeight, width: element.offsetWidth }
  }

  const handleClick = (event: any) => { 
    const height = event.currentTarget.getBoundingClientRect().height;
    setDynamicHeight(height+"px");     
   };

  const isLottieLoading = contextLoader?.isLoading && contextLoader.buttonId === testAutoId;
  const ariaLiveElement = document.getElementById('ariaLive');
  useEffect(() => {
    if (isLottieLoading) {
      if (!document.body.classList.contains('disableInteraction')) {
        disableInteractiveElements();
      }
      if (ariaLiveElement) { ariaLiveElement.innerText = contextLoader?.ariaLabelText }
    }

    return () => {
      if (document.body.classList.contains('disableInteraction')) {
        disableInteractiveElements();
          setContextLoader({animationColor: WHITE, buttonId: '', isLoading: false});
      }
      if (ariaLiveElement) { ariaLiveElement.innerText = '' }
    };
  }, [isLottieLoading]);

  return (
    <button
      className={classNames(className, {
        'is-loading': isLoading,
        'is-disabled': isDisabled,
        [`${className}--outline`]: selected,
      })}
      data-testid={testAutoId}
      data-testauto-id={testAutoId}
      {...buttonProps}
      onClickCapture={(event)=>handleClick(event)}
      style={{
        height: dynamicHeight,
      }}
    >
      {isLottieLoading ? (
        <Lottie
          className={`in-context-loader`}
          animationData={getAnimationData(contextLoader.animationColor)}
          style={styleObj(contextLoader.targetElement)}
        />
      ) : (
        <>
          {!!icon && icon}
          <span className="button__content">{buttonProps.children}</span>
        </>
      )}
    </button>
  );
};

export default Button;
